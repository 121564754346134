import React, { Component } from "react";
import { connect } from "react-redux";

import { Get, Put, Post, GetFile } from "utils/axios";
import { requestError, requestSuccess } from "utils/requestHandler";

const HOC = (WrappedComponent) => {
  class WithHOC extends Component {
    state = {
      loading: false,
      billingList: [],
      packageList: [],
      plansList: [],
      billingPages: 1,
      billingCount: 0,
      showFilterModal: false,
      selectedPostType: "All",
    };

    onChangeHOC = (key, val) => this.setState({ [key]: val });

    load = (param) => this.setState({ loading: param });

    getPlansList = () => {
      Get(
          `/plans`,
          this.getPlansListSuccess,
          (error) => requestError(error),
          this.load
      );
    }
    getPlansListSuccess = (payload) => {
        this.setState({ plansList: payload.data });
    }

    getPackage = () =>
      Get("/packages", this.getPackageSuccess, this.getPackageError, this.load);
    getPackageSuccess = (payload) => {
      this.setState({
        packageList: payload.data,
      });
    };
    getPackageError = (error) => requestError(error);

    getBilling = (querystring = "") =>
      Get(
        `/orders?limit=10` + querystring + (({
          All: '',
          Success: '&status=payment-success',
          Pending: '&status=pending',
          Fail: '&status=payment-failed'
        })[this.state.selectedPostType] ?? ''),
        this.getBillingSuccess,
        this.getBillingError,
        this.load
      );
    getBillingSuccess = (payload) => {
      this.setState({
        billingList: payload.data,
        billingCount: payload.count,
      });
    };
    getBillingError = (error) => requestError(error);

    getInvoice = (id, name) => Get(
      `/generate-invoice-for-order/${id}`,
      this.getInvoiceSuccess,
      error => requestError(error),
      this.load,
      true
    )
    getInvoiceSuccess = payload => {
      let fileURL = payload.ossUrl;
      fetch(fileURL)
        .then(response => response.blob())
        .then(blob => {
          const link = document.createElement('a');
          link.href = URL.createObjectURL(blob);
          link.download = fileURL.split('/').pop() + '.pdf';
          console.log(fileURL.split('/').pop())
          link.click();
          URL.revokeObjectURL(link.href); // Clean up
        })
        .catch(error => console.error('Download failed:', error));
      requestSuccess('Invoice has been downloaded')
    }

    getProformaInvoice = (id, name) => Get(
      `/generate-proforma-invoice-for-order/${id}`,
      this.getProformaInvoiceSuccess,
      error => requestError(error),
      this.load,
      true
    )
    getProformaInvoiceSuccess = payload => {
      let fileURL = payload.ossUrl;
      fetch(fileURL)
        .then(response => response.blob())
        .then(blob => {
          const link = document.createElement('a');
          link.href = URL.createObjectURL(blob);
          link.download = fileURL.split('/').pop() + '.pdf';
          console.log(fileURL.split('/').pop())
          link.click();
          URL.revokeObjectURL(link.href); // Clean up
        })
        .catch(error => console.error('Download failed:', error));
      requestSuccess('Proforma Invoice has been downloaded')
    }

    getReceipt = (id, name) => Get(
      `/generate-receipt-for-order/${id}`,
      this.getReceiptSuccess,
      error => requestError(error),
      this.load,
      true
    )
    getReceiptSuccess = payload => {
      let fileURL = payload.ossUrl;
      fetch(fileURL)
        .then(response => response.blob())
        .then(blob => {
          const link = document.createElement('a');
          link.href = URL.createObjectURL(blob);
          link.download = fileURL.split('/').pop() + '.pdf';
          console.log(fileURL.split('/').pop())
          link.click();
          URL.revokeObjectURL(link.href); // Clean up
        })
        .catch(error => console.error('Download failed:', error));
      requestSuccess('Receipt has been downloaded')
    }

    getPolyInvoice = (data, type) => {
      const polyInvoice = {
        'normal': data?.invoice_number || `Invoice_${data.id}`,
        'proforma': data?.proforma_invoice_number || `Proforma_Invoice_${data.id}`,
        'receipt': data?.receipt_number || `Receipt_${data.id}`,
      }
      // const url = data.order_invoices.find(e => e.type === type).url
      // if(url)
      // return GetFile(
      //   url,
      //   `${polyInvoice[type]}.pdf`,
      //   this.getPolyInvoiceSuccess,
      //   error => requestError(error),
      //   this.load,
      //   true
      // )
      if(type === 'normal') this.getInvoice(data.id, data.invoice_number)
      else if(type === 'proforma') this.getProformaInvoice(data.id, data.proforma_invoice_number)
      else if(type === 'receipt') this.getReceipt(data.id, data.receipt_number)
    }
    getPolyInvoiceSuccess = () => {
      requestSuccess('Invoice has been downloaded')
    }

    render = () => {
      return (
        <WrappedComponent
          {...this.props}
          selectedPostType={this.state.selectedPostType}
          packageList={this.state.packageList}
          billingList={this.state.billingList}
          billingPages={this.state.billingPages}
          billingCount={this.state.billingCount}
          plansList={this.state.plansList}
          onLoad={this.state.loading}
          getPackage={this.getPackage}
          getBilling={this.getBilling}
          onChangeHOC={this.onChangeHOC}
          showFilterModal={this.state.showFilterModal}
          getReceipt={this.getReceipt}
          getInvoice={this.getInvoice}
          getProformaInvoice={this.getProformaInvoice}
          getPolyInvoice={this.getPolyInvoice}
          getPlansList={this.getPlansList}
        />
      );
    };
  }
  const mapStateToProps = (state) => ({ data: state });
  return connect(mapStateToProps)(WithHOC);
};

export default HOC;
