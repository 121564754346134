import React, { useState } from "react";
import _, { set } from "lodash";
import CustomModalBody from "components/Modal/body";
import CustomModalFooter from "components/Modal/footer";
import { Input, Form, FormGroup, Label, Row, Col } from "reactstrap";

export default function FilterForm({ onSubmit, onChangeHOC, handleState }) {

	const [invoiceStartDate, setInvoiceStartDate] = useState("");
	const [invoiceEndDate, setInvoiceEndDate] = useState("");
	const [advertiser, setAdvertiser] = useState("");
	const [billing_name, setBillingName] = useState("");
	const [plan, setPlan] = useState("");
	const [orderDesc, setOrderDesc] = useState("");
	const [planPrice, setPlanPrice] = useState("");
	const [sellingPrice, setSellingPrice] = useState("");
	const [tax, setTax] = useState("");
	const [priceAfterTax, setPriceAfterTax] = useState("");
	const [paymentMethod, setPaymentMethod] = useState("");
	const [agentID, setAgentID] = useState("");
	const [agentName, setAgentName] = useState("");
	const [agentLeadID, setAgentLeadID] = useState("");
	const [agentLeadName, setAgentLeadName] = useState("");
	const [promoCode, setPromoCode] = useState("");
	const [printAdsReference, setPrintAdsReference] = useState("");
	const [paymentRefNo, setPaymentRefNo] = useState("");
	const [printPrice, setPrintPrice] = useState("");

	const handleClear = () => {
		setInvoiceStartDate("");
		setInvoiceEndDate("");
		setAdvertiser("");
		setBillingName("");
		setPlan("");
		setOrderDesc("");
		setPlanPrice("");
		setSellingPrice("");
		setTax("");
		setPriceAfterTax("");
		setPaymentMethod("");
		setAgentID("");
		setAgentName("");
		setAgentLeadID("");
		setAgentLeadName("");
		setPromoCode("");
		setPrintAdsReference("");
		setPaymentRefNo("");
		setPrintPrice("");
	};

	const generateQuery = () => {
		let queryString = "";

		if (invoiceStartDate){ queryString += `&invoice_start_date=${invoiceStartDate}`}
		if (invoiceEndDate){ queryString += `&invoice_end_date=${invoiceEndDate}`}
		if (orderDesc){ queryString += `&order_description=${orderDesc}`}
		if (agentID){ queryString += `&agent_id=${agentID}`}
		if (agentName){ queryString += `&agent_name=${agentName}`}
		if (invoiceStartDate){ queryString += `&start_date=${invoiceStartDate}`}
		if (invoiceEndDate){ queryString += `&end_date=${invoiceEndDate}`}
		if (advertiser){ queryString += `&advertiser_name=${advertiser}`}
		if (billing_name){ queryString += `&billing_name=${billing_name}`}
		if (plan){ queryString += `&plan=${plan}`}
		if (planPrice){ queryString += `&plan_price=${planPrice}`}
		if (sellingPrice){ queryString += `&selling_price=${sellingPrice}`}
		if (tax){ queryString += `&tax=${tax}`}
		if (priceAfterTax){ queryString += `&price_after_tax=${priceAfterTax}`}
		if (paymentMethod){ queryString += `&payment_method=${paymentMethod}`}
		if (agentLeadID){ queryString += `&agent_lead_id=${agentLeadID}`}
		if (agentLeadName){ queryString += `&agent_lead_name=${agentLeadName}`}
		if (promoCode){ queryString += `&promo_code=${promoCode}`}
		if (printAdsReference){ queryString += `&print_ads_reference=${printAdsReference}`}
		if (paymentRefNo){ queryString += `&payment_reference_number=${paymentRefNo}`}
		if (printPrice){ queryString += `&print_ads_amount=${printPrice}`}

		return queryString;
	};

	return (
		<>
			<CustomModalBody>
				<Form id="billing-filter-form">
					<Row>
						<Col lg={3}>
							<FormGroup>
								<Label for="invoiceStartDate">Invoice Start Date </Label>
								<Input
									type="date"
									placeholder="Enter Start Date"
									name="invoiceStartDate"
									id="invoiceStartDate"
									value={invoiceStartDate}
									onChange={(e) => {setInvoiceStartDate(e.target.value)}}
								/>
							</FormGroup>
						</Col>
						<Col lg={3}>
							<FormGroup>
								<Label for="invoiceEndDate">Invoice End Date </Label>
								<Input
									type="date"
									placeholder="Enter End Date"
									name="invoiceEndDate"
									id="invoiceEndDate"
									value={invoiceEndDate}
									onChange={(e) => {setInvoiceEndDate(e.target.value)}}
								/>
							</FormGroup>
						</Col>
						<Col lg={3}>
							<FormGroup>
								<Label for="advertiser">Advertiser Name </Label>
								<Input
									placeholder="Enter Advertiser Name"
									name="advertiser"
									id="advertiser"
									value={advertiser}
									onChange={(e) => {setAdvertiser(e.target.value)}}
								/>
							</FormGroup>
						</Col>
						<Col lg={3}>
							<FormGroup>
								<Label for="billing_name">Billing Name </Label>
								<Input
									placeholder="Enter Billing Name"
									name="billing_name"
									id="billing_name"
									value={billing_name}
									onChange={(e) => {setAdvertiser(e.target.value)}}
								/>
							</FormGroup>
						</Col>
						<Col lg={3}>
							<FormGroup>
								<Label for="plan">Plan</Label>
								<Input
									placeholder="Enter Plan"
									name="plan"
									id="plan"
									value={plan}
									onChange={(e) => {setPlan(e.target.value)}}
								/>
							</FormGroup>
						</Col>
						<Col lg={3}>
							<FormGroup>
								<Label for="orderDesc">Order Description</Label>
								<Input
									placeholder="Enter Order Description"
									name="orderDesc"
									id="orderDesc"
									value={orderDesc}
									onChange={(e) => {setOrderDesc(e.target.value)}}
								/>
							</FormGroup>
						</Col>
						{/* <Col lg={3}>
							<FormGroup>
								<Label for="planPrice">Plan Price</Label>
								<Input
									placeholder="Enter Plan"
									name="planPrice"
									id="planPrice"
									value={planPrice}
									onChange={(e) => {setPlanPrice(e.target.value)}}
								/>
							</FormGroup>
						</Col>
						<Col lg={3}>
							<FormGroup>
								<Label for="sellingPrice">Selling Price</Label>
								<Input
									placeholder="Enter Selling Price"
									name="sellingPrice"
									id="sellingPrice"
									value={sellingPrice}
									onChange={(e) => {setSellingPrice(e.target.value)}}
								/>
							</FormGroup>
						</Col>
						<Col lg={3}>
							<FormGroup>
								<Label for="tax">Tax</Label>
								<Input
									placeholder="Enter Tax"
									name="tax"
									id="tax"
									value={tax}
									onChange={(e) => {setTax(e.target.value)}}
								/>
							</FormGroup>
						</Col>
						<Col lg={3}>
							<FormGroup>
								<Label for="priceAfterTax">Price After Tax</Label>
								<Input
									placeholder="Enter Price After Tax"
									name="priceAfterTax"
									id="priceAfterTax"
									value={priceAfterTax}
									onChange={(e) => {setPriceAfterTax(e.target.value)}}
								/>
							</FormGroup>
						</Col> */}
						<Col lg={3}>
							<FormGroup>
								<Label for="paymentMethod">Payment Method</Label>
								<Input
									placeholder="Enter Payment Method"
									name="paymentMethod"
									id="paymentMethod"
									value={paymentMethod}
									onChange={(e) => {setPaymentMethod(e.target.value)}}
								/>
							</FormGroup>
						</Col>
						<Col lg={3}>
							<FormGroup>
								<Label for="agentID">Agent ID</Label>
								<Input
									placeholder="Enter Agent ID"
									name="agentID"
									id="agentID"
									value={agentID}
									onChange={(e) => {setAgentID(e.target.value)}}
								/>
							</FormGroup>
						</Col>
						<Col lg={3}>
							<FormGroup>
								<Label for="agentName">Agent Name </Label>
								<Input
									placeholder="Enter Agent Name"
									name="agentName"
									id="agentNamee"
									value={agentName}
									onChange={(e) => {setAgentName(e.target.value)}}
								/>
							</FormGroup>
						</Col>
						<Col lg={3}>
							<FormGroup>
								<Label for="agentLeadID">Agent Lead ID</Label>
								<Input
									placeholder="Enter Agent Lead ID"
									name="agentLeadID"
									id="agentLeadID"
									value={agentLeadID}
									onChange={(e) => {setAgentLeadID(e.target.value)}}
								/>
							</FormGroup>
						</Col>
						<Col lg={3}>
							<FormGroup>
								<Label for="agentLeadName">Agent Lead Name</Label>
								<Input
									placeholder="Enter Agent Lead Name"
									name="agentLeadName"
									id="agentLeadName"
									value={agentLeadName}
									onChange={(e) => {setAgentLeadName(e.target.value)}}
								/>
							</FormGroup>
						</Col>
						<Col lg={3}>
							<FormGroup>
								<Label for="promoCode">Promo Code</Label>
								<Input
									placeholder="Enter Promo Code"
									name="promoCode"
									id="promoCode"
									value={promoCode}
									onChange={(e) => {setPromoCode(e.target.value)}}
								/>
							</FormGroup>
						</Col>
						<Col lg={3}>
							<FormGroup>
								<Label for="printAdsReference">Print Ads Reference</Label>
								<Input
									placeholder="Enter Print Ads Reference"
									name="printAdsReference"
									id="printAdsReference"
									value={printAdsReference}
									onChange={(e) => {setPrintAdsReference(e.target.value)}}
								/>
							</FormGroup>
						</Col>
						<Col lg={3}>
							<FormGroup>
								<Label for="paymentRefNo">Payment Ref No.</Label>
								<Input
									placeholder="Enter Payment Ref No."
									name="paymentRefNo"
									id="paymentRefNo"
									value={paymentRefNo}
									onChange={(e) => {setPaymentRefNo(e.target.value)}}
								/>
							</FormGroup>
						</Col>
						{/* <Col lg={3}>
							<FormGroup>
								<Label for="printPrice">Print Price</Label>
								<Input
									placeholder="Enter Print Price"
									name="printPrice"
									id="printPrice"
									value={printPrice}
									onChange={(e) => {setPrintPrice(e.target.value)}}
								/>
							</FormGroup>
						</Col> */}
					</Row>
				</Form>
			</CustomModalBody>
			<CustomModalFooter
				rightButton={[
					{
						color: "danger",
						outline: true,
						content: "Clear All",
						onClick: () => handleClear(),
					},
					{
						color: "primary",
						content: "Submit",
						onClick: () => {
							const queryString = generateQuery();
							onSubmit(queryString);
							handleState({ filterQuery: queryString });
							onChangeHOC("showFilterModal", false);
							onChangeHOC("advertiserPages", 1);
						},
					},
				]}
			/>
		</>
	);
}
