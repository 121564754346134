import React, { useState } from "react";
import _ from "lodash";
import CustomModalBody from "components/Modal/body";
import CustomModalFooter from "components/Modal/footer";
import { Input, Form, FormGroup, Label, Row, Col } from "reactstrap";

export default function FilterForm({ onSubmit, onChangeHOC, handleState }) {
	const [invoiceStartDate, setInvoiceStartDate] = useState(null);
	const [invoiceEndDate, setInvoiceEndDate] = useState(null);
	const [advertiserId, setAdvertiserId] = useState("");
	const [advertiser, setAdvertiser] = useState("");
	const [invoiceNumber, setInvoiceNumber] = useState("");
	const [corresponding_invoice_no, setCorrespondingInvoiceNo] = useState("");
	const [billingName, setBillingName] = useState("");
	const [agentName, setAgentName] = useState("");
	const [sellingPrice, setSellingPrice] = useState("");
	const [orderDesc, setOrderDesc] = useState("");
	const [agentID, setAgentID] = useState("");
	const [agentLeadID, setAgentLeadID] = useState("");
	const [promoCode, setPromoCode] = useState("");
	const [paymentRefNo, setPaymentRefNo] = useState("");
	const [agentLeadName, setAgentLeadName] = useState("");
	const [plan, setPlan] = useState("");
	const [paymentMethod, setPaymentMethod] = useState("");
	const [printAdsReference, setPrintAdsReference] = useState("");
	const [paymentDate, setPaymentDate] = useState("");


	const handleClear = () => {
		setInvoiceStartDate("");
		setInvoiceEndDate("");
		setAdvertiserId("");
		setAdvertiser("");
		setInvoiceNumber("");
		setCorrespondingInvoiceNo("");
		setBillingName("");
		setAgentName("");
		setSellingPrice("");
		setOrderDesc("");
		setAgentLeadID("");
		setPromoCode("");
		setPaymentRefNo("");
		setAgentLeadName("");
		setPlan("");
		setPaymentMethod("");
		setPrintAdsReference("");
		setAgentID("");
		setAgentLeadID("");
		setAgentName("");
		setAgentLeadName("");
		setPaymentDate("");
	};

	const generateQuery = () => {
		let queryString = "";

		if (invoiceStartDate){ queryString += `&invoice_start_date=${invoiceStartDate}`}
		if (invoiceEndDate){ queryString += `&invoice_end_date=${invoiceEndDate}`}
		if (invoiceNumber){ queryString += `&invoice_no=${invoiceNumber}`}
		if (billingName){ queryString += `&billing_name=${billingName}`}
		if (orderDesc){ queryString += `&order_description=${orderDesc}`}
		if (sellingPrice){ queryString += `&selling_price=${sellingPrice}`}
		if (agentID) { queryString += `&agent_id=${agentID}` }
		if (agentName){ queryString += `&agent_name=${agentName}`}
		if (agentLeadID){ queryString += `&agent_lead_id=${agentLeadID}`}
		if (agentLeadName){ queryString += `&agent_lead_name=${agentLeadName}`}
		if (promoCode){ queryString += `&promo_code=${promoCode}`}
		if (paymentRefNo){ queryString += `&payment_ref_number=${paymentRefNo}`}
		if (advertiserId){ queryString += `&advertiser_id=${advertiserId}`}
		if (advertiser){ queryString += `&company_name=${advertiser}`}
		if (plan){ queryString += `&plan_name=${plan}`}
		if (paymentMethod){ queryString += `&payment_method=${paymentMethod}`}
		if (printAdsReference){ queryString += `&print_ads_reference=${printAdsReference}`}
		if (paymentDate){ queryString += `&payment_date=${paymentDate}`}
		if (corresponding_invoice_no){ queryString += `&corresponding_invoice_no=${corresponding_invoice_no}`}

		return queryString;
	};

	return (
		<>
			<CustomModalBody>
				<Form id="billing-filter-form">
					<Row>
						<Col lg={3}>
							<FormGroup>
								<Label for="invoiceStartDate">Proforma Invoice Start Date </Label>
								<Input
									type="date"
									placeholder="Enter Start Date"
									name="invoiceStartDate"
									id="invoiceStartDate"
									value={invoiceStartDate}
									onChange={(e) => {setInvoiceStartDate(e.target.value)}}
								/>
							</FormGroup>
						</Col>
						<Col lg={3}>
							<FormGroup>
								<Label for="invoiceEndDate">Proforma Invoice End Date </Label>
								<Input
									type="date"
									placeholder="Enter "
									name="invoiceEndDate"
									id="invoiceEndDate"
									value={invoiceEndDate}
									onChange={(e) => {setInvoiceEndDate(e.target.value)}}
								/>
							</FormGroup>
						</Col>
						<Col lg={3}>
							<FormGroup>
								<Label for="invoiceNumber">Invoice No. </Label>
								<Input
									placeholder="Enter Invoice No."
									name="invoiceNumber"
									id="invoiceNumber"
									value={invoiceNumber}
									onChange={(e) => {setInvoiceNumber(e.target.value)}}
								/>
							</FormGroup>
						</Col>
						<Col lg={3}>
							<FormGroup>
								<Label for="corresponding_invoice_no">Corresponding Invoice No. </Label>
								<Input
									placeholder="Enter Corresponding Invoice No."
									name="corresponding_invoice_no"
									id="corresponding_invoice_no"
									value={corresponding_invoice_no}
									onChange={(e) => {setCorrespondingInvoiceNo(e.target.value)}}
								/>
							</FormGroup>
						</Col>
						{/* <Col lg={3}>
							<FormGroup>
								<Label for="advertiserId">Advertiser ID </Label>
								<Input
									placeholder="Enter Advertiser ID"
									name="advertiserId"
									id="advertiserId"
									value={advertiserId}
									onChange={(e) => {setAdvertiserId(e.target.value)}}
								/>
							</FormGroup>
						</Col> */}
						<Col lg={3}>
							<FormGroup>
								<Label for="advertiser">Advertiser Name </Label>
								<Input
									placeholder="Enter Advertiser Name"
									name="advertiser"
									id="advertiser"
									value={advertiser}
									onChange={(e) => {setAdvertiser(e.target.value)}}
								/>
							</FormGroup>
						</Col>
						<Col lg={3}>
							<FormGroup>
								<Label for="billingName">Billing Name </Label>
								<Input
									placeholder="Enter Billing Name"
									name="billingName"
									id="billingName"
									value={billingName}
									onChange={(e) => {setBillingName(e.target.value)}}
								/>
							</FormGroup>
						</Col>
						<Col lg={3}>
							<FormGroup>
								<Label for="plan">Plan</Label>
								<Input
									placeholder="Enter Plan"
									name="plan"
									id="plan"
									value={plan}
									onChange={(e) => {setPlan(e.target.value)}}
								/>
							</FormGroup>
						</Col>
						<Col lg={3}>
							<FormGroup>
								<Label for="orderDesc">Order Description</Label>
								<Input
									placeholder="Enter Order Description"
									name="orderDesc"
									id="orderDesc"
									value={orderDesc}
									onChange={(e) => {setOrderDesc(e.target.value)}}
								/>
							</FormGroup>
						</Col>
						<Col lg={3}>
							<FormGroup>
								<Label for="paymentMethod">Payment Method</Label>
								<Input
									placeholder="Enter Payment Method"
									name="paymentMethod"
									id="paymentMethod"
									value={paymentMethod}
									onChange={(e) => {setPaymentMethod(e.target.value)}}
								/>
							</FormGroup>
						</Col>
						<Col lg={3}>
							<FormGroup>
								<Label for="agentID">Agent ID</Label>
								<Input
									placeholder="Enter Agent ID"
									name="agentID"
									id="agentID"
									value={agentID}
									onChange={(e) => {setAgentID(e.target.value)}}
								/>
							</FormGroup>
						</Col>
						<Col lg={3}>
							<FormGroup>
								<Label for="agentName">Agent Name </Label>
								<Input
									placeholder="Enter Agent Name"
									name="agentName"
									id="agentName"
									value={agentName}
									onChange={(e) => {setAgentName(e.target.value)}}
								/>
							</FormGroup>
						</Col>
						<Col lg={3}>
							<FormGroup>
								<Label for="agentLeadID">Agent Lead ID </Label>
								<Input
									placeholder="Enter Agent Lead ID"
									name="agentLeadID"
									id="agentLeadID"
									value={agentLeadID}
									onChange={(e) => {setAgentLeadID(e.target.value)}}
								/>
							</FormGroup>
						</Col>
						<Col lg={3}>
							<FormGroup>
								<Label for="agentLeadName">Agent Lead Name</Label>
								<Input
									placeholder="Enter Agent Lead Name"
									name="agentLeadName"
									id="agentLeadName"
									value={agentLeadName}
									onChange={(e) => {setAgentLeadName(e.target.value)}}
								/>
							</FormGroup>
						</Col>
						<Col lg={3}>
							<FormGroup>
								<Label for="promoCode">Promo Code </Label>
								<Input
									placeholder="Enter Promo Code"
									name="promoCode"
									id="promoCode"
									value={promoCode}
									onChange={(e) => {setPromoCode(e.target.value)}}
								/>
							</FormGroup>
						</Col>
						<Col lg={3}>
							<FormGroup>
								<Label for="printAdsReference">Print Ads Reference</Label>
								<Input
									placeholder="Enter Print Ads Reference"
									name="printAdsReference"
									id="printAdsReference"
									value={printAdsReference}
									onChange={(e) => {setPrintAdsReference(e.target.value)}}
								/>
							</FormGroup>
						</Col>
						<Col lg={3}>
							<FormGroup>
								<Label for="paymentDate">Payment Date </Label>
								<Input
									type="date"
									placeholder="Enter Payment Date"
									name="paymentDate"
									id="paymentDate"
									value={paymentDate}
									onChange={(e) => {setPaymentDate(e.target.value)}}
								/>
							</FormGroup>
						</Col>
					</Row>
				</Form>
			</CustomModalBody>
			<CustomModalFooter
				rightButton={[
					{
						color: "danger",
						outline: true,
						content: "Clear All",
						onClick: () => handleClear(),
					},
					{
						color: "primary",
						content: "Submit",
						onClick: () => {
							const queryString = generateQuery();
							onSubmit(queryString);
							handleState({ filterQuery: queryString });
							onChangeHOC("showFilterModal", false);
							onChangeHOC("advertiserPages", 1);
						},
					},
				]}
			/>
		</>
	);
}
