export const MainNav = [
	{
		moduleID: 1,
		icon: "pe-7s-graph1",
		label: "Dashboard",
		to: "#/dashboard",
	},
	{
		moduleID: 2,
		icon: "pe-7s-smile",
		label: "Manage Advertiser Profile",
		to: "#/advertiser-profile",
	},
	{
		moduleID: 3,
		icon: "pe-7s-file",
		label: "Manage Post",
		to: "#/post",
	},
	{
		moduleID: 4,
		icon: "pe-7s-mail",
		label: "Manage Inbox",
		to: "#/inbox",
	},
	{
		moduleID: 5,
		icon: "pe-7s-ticket",
		label: "Manage Promo Code",
		to: "#/promo-code",
	},
	{
		moduleID: 6,
		icon: "pe-7s-browser",
		label: "Manage Banner (Pop-Up)",
		to: "#/banner-popup",
	},
	{
		moduleID: 7,
		icon: "pe-7s-browser",
		label: "Manage Banner (In-House)",
		to: "#/banner-inhouse",
	},
	{
		moduleID: 8,
		icon: "pe-7s-cash",
		label: "Billing",
		to: "#/billing",
	},
	{
		moduleID: 9,
		icon: "pe-7s-user",
		label: "Candidate List",
		to: "#/candidate-list",
	},
	{
		moduleID: 10,
		icon: "pe-7s-note",
		label: "Manage User",
		to: "#/manage-user",
	},
	{
		moduleID: 11,
		icon: "pe-7s-star",
		label: "求职者推荐 ( For Advertiser )",
		to: "#/applicant-recommendation-advertiser",
	},
	{
		moduleID: 12,
		icon: "pe-7s-star",
		label: "求职者推荐 ( For Candidate )",
		to: "#/applicant-recommendation-candidate",
	},
	{
		moduleID: 13,
		icon: "pe-7s-pen",
		label: "Analytic Report",
		content: [
			{
				label: "Sales Report",
				to: "#/sales-report",
			},
			{
				label: "Sales Report By Category",
				to: "#/sales-report-category",
			},
			{
				label: "Sales Report By Product",
				to: "#/sales-report-product",
			},
			{
				label: "Revenue Report",
				to: "#/revenue-report",
			},
			{
				label: "In House Ads Report",
				to: "#/in-house-ads-report",
			},
			// {
			// 	label: "Monthly Sales Report",
			// 	to: "#/monthly-sales-report",
			// },
			// {
			// 	label: "Invoice Report",
			// 	to: "#/invoice-report",
			// },
			{
				label: "Profoma Invoice Report",
				to: "#/profoma-invoice-report",
			},
			{
				label: "Bulk Download Invoice & Receipt",
				to: "#/bulk-download-invoice-and-receipt",
			},
			// {
			// 	"label": "CP Sales Report",
			// 	"to": "#/cp-sales-report",
			// },
			// {
			// 	"label": "SC Sales Report",
			// 	"to": "#/sc-sales-report",
			// },
			// {
			// 	"label": "Unutilised Sales Report",
			// 	"to": "#/unutilised-sales-report",
			// },
			// {
			// 	"label": "Forfeit Report",
			// 	"to": "#/forfeit-sales-report",
			// },
		],
	},
	{
		moduleID: 14,
		icon: "pe-7s-note2",
		label: "Audit Log",
		content: [
			{
				icon: "pe-7s-note2",
				label: "Users Table",
				to: "#/audit-log/user",
			},
			{
				icon: "pe-7s-note2",
				label: "Orders Table",
				to: "#/audit-log/orders",
			},
			{
				icon: "pe-7s-note2",

				label: "Customer Data Table",
				to: "#/audit-log/customer",
			},
			{
				icon: "pe-7s-note2",

				label: "Payment Methods Table",
				to: "#/audit-log/payment-method",
			},
			{
				icon: "pe-7s-note2",
				label: "Admin Actions Table",
				to: "#/audit-log/admin-actions",
			},
		],
	},
];
